import React from 'react';
import './App.css';

function App() {
  return (
    <>
      <h1 className="title">Boost7</h1>
      <div className="container">
        <button onClick={() => window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=1104426363177414656&redirect_uri=https%3A%2F%2Ffaas-lon1-917a94a7.doserverless.co%2Fapi%2Fv1%2Fweb%2Ffn-16bde9c0-84d5-45dc-9c18-b48c766112ef%2Flink%2Flink%2F&response_type=code&scope=identify'}>Connect</button>
      </div>
    </>
  );
}

export default App;
